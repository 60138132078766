
// const baseDev = `https://goadmin.yuansiwei.com/api/v1/`, //开发环境
//     basePro = `${window.location.protocol}//goadmin.yuansiwei.com/api/v1/`; //生产环境（测试）
// const baseDev = `https://adm.yuansiwei.com/index.php?r=mobileapp/`, //开发环境
// const baseDev = `http://zmadm.yuansiwei.com/index.php?r=mobileapp/`, //开发环境
const baseDev = `http://zm.vipgz4.idcfengye.com/index.php?r=mobileapp/`, //开发环境
    basePro = `${window.location.protocol}//http://admin.yuansiwei.com/index.php?r=mobileapp/`; //生产环境（测试）

console.log('baseDev', baseDev, 'basePro', basePro);

const baseUrl = process.env.NODE_ENV === "development" ? baseDev : basePro;

const base = {
    baseUrl:process.env.NODE_ENV === "development" ? baseDev : basePro,
    appName:'com.markingCheckStudy.app',
    platform:'WebFrontEnd',
    loginMode:'default',
    version:'1.1.0'
}

export default base;
