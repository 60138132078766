<template>
    <div class="codeLoginPage">
        <div class="logo" :style="{height:`${winH * 0.3}px`}">
            <img :src="logoImg ? logoImg : require('../../public/transform/defaultLogo.png')" class="login"/>
        </div>
        <div class="form_box" :style="{height:`${winH * 0.5}px`}">
            <van-form class="form_item" @submit="onLogin" v-if="!isReg">
                <van-field
                        v-model="phone"
                        type="tel"
                        name="用户名"
                        placeholder="用户名"
                        :rules="[{ required: true, message: '请填写用户名' },{ validator, message: '请输入正确的用户名' }]"
                />

                <van-field
                        v-model="password"
                        :type="showPassword?'text':'password'"
                        name="密码"
                        placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                        @click-right-icon="showPassword = !showPassword"
                >
<!--                    <div class="inputType" slot="right-icon">-->
<!--                        <img v-if="!showPassword" src="../../public/iconImg/open-eye.png" alt="">-->
<!--                        <img v-else src="../../public/iconImg/close-eye.png" alt="">-->
<!--                    </div>-->
                </van-field>
<!--                <van-field-->
<!--                        v-model="SMSCode"-->
<!--                        type="text"-->
<!--                        name="验证码"-->
<!--                        placeholder="请输入验证码"-->
<!--                        :rules="[{ required: true, message: '请输入验证码' }]"-->
<!--                        @click-right-icon="getSMSCode"-->
<!--                >-->
<!--                    <div class="inputType" slot="right-icon">-->
<!--                        <div class="get_code" v-if="descTime === 0" @click="sendSMSCode">{{SMSSend ? '重新获取' : '获取验证码'}}</div>-->
<!--                        <div class="got_code" v-if="descTime > 0">已发送（{{descTime}}s）</div>-->
<!--                    </div>-->
<!--                </van-field>-->


            </van-form>
            <van-form class="form_item" @submit="onLogin" v-if="isReg">
                <van-field
                        v-model="phone"
                        type="tel"
                        name="用户名"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请填写用户名' },{ validator, message: '请输入正确的用户名' }]"
                />

                <van-field
                        v-model="password"
                        :type="showPassword?'text':'password'"
                        name="密码"
                        placeholder="输入6-20位字符密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                        @click-right-icon="showPassword = !showPassword"
                >
<!--                    <div class="inputType" slot="right-icon">-->
<!--                        <img v-if="!showPassword" src="../../public/iconImg/open-eye.png" alt="">-->
<!--                        <img v-else src="../../public/iconImg/close-eye.png" alt="">-->
<!--                    </div>-->
                </van-field>
                <van-field
                        v-model="SMSCode"
                        type="text"
                        name="验证码"
                        placeholder="请输入验证码"
                        :rules="[{ required: true, message: '请输入验证码' }]"
                        @click-right-icon="getSMSCode"
                >
                    <div class="inputType" slot="right-icon">
                        <div class="get_code" v-if="descTime === 0" @click="sendSMSCode">{{SMSSend ? '重新获取' : '获取验证码'}}</div>
                        <div class="got_code" v-if="descTime > 0">已发送（{{descTime}}s）</div>
                    </div>
                </van-field>
<!--                <div class="submit_btn" :class="{disabled:!submitAble}" @click="onLogin">登录</div>-->
<!--                <div class="agreement">-->
<!--                    注册和登录即代表您同意 <span class="agreement_text" @click="userAction('agreement')">《服务协议及隐私政策》</span>-->
<!--                </div>-->

            </van-form>
            <div class="submit_btn" :class="{disabled:!submitAble}" @click="onLogin">{{!isReg ? '登录' : '注册并登录'}}</div>
            <div class="agreement">
                注册和登录即代表您同意 <span class="agreement_text" @click="userAction('agreement')">《服务协议及隐私政策》</span>
            </div>
            <div class="user_action" @click="isReg = !isReg">
                {{!isReg ? '立即注册' : '已有账号，立即登录'}}
            </div>
        </div>
        <div class="footer">
<!--            <div class="action_item retrieve" @click="userAction('retrieve')">忘记密码</div>-->
<!--            <div class="action_item register" v-if="false" @click="userAction('register')">注册账号</div>-->
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";
    import UserApi from "../api/UserApi";
    import base from "../api/base";

    export default {
        name: "CodeLogin",
        data() {
            return {
                //窗口高度
                winH:0,
                phone: '',
                password: '',
                showPassword:false,
                //验证码
                SMSCode:'',
                //是否已经发送过验证码
                SMSSend:false,
                //验证码倒计时
                descTime:0,
                //验证码初始化时间,默认60s，用于重置倒计时
                descTimeInit:60,
                //验证码计时器
                timer:null,

                //url参数
                urlParams:{},
                //是否正在提交
                isSubmit:false,

                //是否登录，默认否，表示当前为登录页面，当true时表示当前页面是注册页面
                isReg:false,
            }
        },
        mounted() {
            this.pageInit();
            this.timerStart();
        },
        computed: {
            ...mapGetters({
                //logo信息
                logoInfo: 'common/getLogoInfo',
                //获取学校id
                schoolId: 'common/getSchoolId',
                //获取用户信息
                userInfo:'user/getUserInfo'
            }),

            logoImg() {
                let schoolId = this.$L.myLocal('__SCHOOL_ID__') || this.schoolId;

                let target = this.logoInfo.filter(item => item.schoolId == schoolId);
                // console.log('target', target);
                // debugger
                if (target[0]) {
                    return target[0].logoSrc;
                }
                return null;

            },
            //简单判断是否可以提交，用于计算按钮
            submitAble(){
                return this.isReg ? (this.$A.isMobile(this.phone) && this.password  && this.SMSCode) : (this.$A.isMobile(this.phone) && this.password)
            }
        },
        methods: {
            ...mapMutations({
                //设置用户信息
                setUserInfo:'user/setUserInfo'
            }),
            //页面类别切换

            //用户操作跳转
            userAction(type){
                let path;
                switch (type) {
                    case 'retrieve':
                        path = '/Retrieve';
                        break;
                    case 'register':
                        path = '/Register';
                        break;
                    case 'agreement':
                        path = '/Agreement'
                        break;
                }
                if(path) this.$router.push({path:path});
            },
            //用户名校验
            validator(val) {
                return this.$A.isMobile(val);
            },

            //发送验证码
            sendSMSCode(){
                if(!this.phone){
                    this.$toast('请先输入手机号');
                    return false;
                }else if(!this.$A.isMobile(this.phone)){
                    this.$toast('请输入正确的手机号');
                    return false;
                }
                //异步操作
                UserApi.sendBindPhoneVerifyCode({phoneNumber: this.phone}).then(res=>{
                    console.log('sendBindPhoneVerifyCode',res);
                    if(res.success){
                        //重置倒计时
                        this.SMSSend = true;
                        this.descTime = this.descTimeInit;
                    }
                })



            },

            //获取验证码
            getSMSCode(){
                console.log('获取验证码');
            },
            //计时器开始
            timerStart(){
                if(this.timer) clearInterval(this.timer);
                this.timer = setInterval(()=>{
                    if(this.descTime > 0) this.descTime --;

                },1000)
            },

            //登录
            onLogin(){
                // if(!this.isSubmit){
                //     this.isSubmit = true;
                // }
                // let params,methodName;
                // if(this.isReg){
                //     params = {
                //         loginMode:base.loginMode,
                //         platform:base.platform,
                //         phone:this.phone,
                //         verify_code:this.SMSCode,
                //         id:this.urlParams.id,
                //         password:this.password,
                //     };
                //     methodName = 'addExaminationScan';
                // }else{
                //     params = {
                //         loginMode: 'default',
                //         username: this.phone,
                //         schoolId:  this.$L.myLocal('__SCHOOL_ID__') || this.schoolId,
                //         password: this.password,
                //     };
                //     methodName = 'login';
                // }

                if(this.submitAble){
                    console.log('登录按钮');
                    let params = {
                        loginMode:base.loginMode,
                        platform:base.platform,
                        phone:this.phone,
                        verify_code:this.SMSCode,
                        id:this.urlParams.id,
                        password:this.password,
                        type:this.isReg ? 2 : 1,
                    };
                    // UserApi.addExaminationScan(params).then(res=>{
                    UserApi.addExaminationScan(params).then(res=>{
                        console.log(res);
                        if(res.success){
                            //用户信息写入本地缓存
                            this.$L.myLocal('__USER_INFO__',res.data);
                            let session = res.data.session || '';
                            this.$L.myLocal('__SESSION__',session);

                            //设置vuex状态用户信息
                            this.setUserInfo(res.data);

                            //跳转精准作业
                            try{
                                let classId = this.userInfo.classes[0];
                                let testId = this.userInfo.testId;
                                let studentId = this.userInfo.id;
                                let type = this.jumpType || 'studentWaitingPhoto';
                                if (res.data.testType == 8){
                                    this.$router.push({path: `/TestPage/${testId}/0`})
                                }else {
                                    this.$router.push({
                                        name: 'taskFeedback',
                                        query: {
                                            studentId: studentId,
                                            testId: testId,
                                            classId: classId,
                                            correctable: type
                                        }
                                    })
                                }
                            }catch (e) {
                                console.log(e);
                            }

                        }
                    })

                    return false;
                    UserApi.login(params).then(res=>{
                        console.log(res);
                        if(res.success){
                            this.$toast.success('登录成功');
                            //用户信息写入本地缓存
                            this.$L.myLocal('__USER_INFO__',res.data);
                            let session = res.data.session || '';
                            this.$L.myLocal('__SESSION__',session);

                            //设置vuex状态用户信息
                            this.setUserInfo(res.data);


                            setTimeout(()=>{
                                this.$router.replace({path:'/Task'});
                            },1000)
                        }
                    })
                }

                // if(this.isReg){
                //     if(this.submitAble){
                //         console.log('登录按钮');
                //         let params = {
                //             loginMode:base.loginMode,
                //             platform:base.platform,
                //             phone:this.phone,
                //             verify_code:this.SMSCode,
                //             id:this.urlParams.id,
                //             password:this.password,
                //         };
                //         UserApi.addExaminationScan(params).then(res=>{
                //             console.log(res);
                //             if(res.success){
                //                 //用户信息写入本地缓存
                //                 this.$L.myLocal('__USER_INFO__',res.data);
                //                 let session = res.data.session || '';
                //                 this.$L.myLocal('__SESSION__',session);
                //
                //                 //设置vuex状态用户信息
                //                 this.setUserInfo(res.data);
                //
                //                 //跳转精准作业
                //                 try{
                //                     let classId = this.userInfo.classes[0];
                //                     let testId = this.userInfo.testId;
                //                     let studentId = this.userInfo.id;
                //                     let type = this.jumpType || 'studentWaitingPhoto';
                //
                //                     this.$router.push({
                //                         name: 'taskFeedback',
                //                         query: {
                //                             studentId: studentId,
                //                             testId: testId,
                //                             classId: classId,
                //                             correctable: type
                //                         }
                //                     })
                //                 }catch (e) {
                //                     console.log(e);
                //                 }
                //
                //             }
                //         })
                //
                //         return false;
                //         UserApi.login(params).then(res=>{
                //             console.log(res);
                //             if(res.success){
                //                 this.$toast.success('登录成功');
                //                 //用户信息写入本地缓存
                //                 this.$L.myLocal('__USER_INFO__',res.data);
                //                 let session = res.data.session || '';
                //                 this.$L.myLocal('__SESSION__',session);
                //
                //                 //设置vuex状态用户信息
                //                 this.setUserInfo(res.data);
                //
                //
                //                 setTimeout(()=>{
                //                     this.$router.replace({path:'/Task'});
                //                 },1000)
                //             }
                //         })
                //     }
                // }else{
                //     let params = {
                //         loginMode: 'default',
                //         username: this.phone,
                //         schoolId:  this.$L.myLocal('__SCHOOL_ID__') || this.schoolId,
                //         password: this.password,
                //     }
                //     UserApi.login(this.loginParams).then(res=>{
                //         console.log(res);
                //         if(res.success){
                //             this.$toast.success('登录成功');
                //             //用户信息写入本地缓存
                //             this.$L.myLocal('__USER_INFO__',res.data);
                //             let session = res.data.session || '';
                //             this.$L.myLocal('__SESSION__',session);
                //
                //             //设置vuex状态用户信息
                //             this.setUserInfo(res.data);
                //
                //
                //             setTimeout(()=>{
                //                 this.$router.replace({path:'/Task'});
                //             },1000)
                //         }
                //         this.submitAble = true;
                //     })
                // }


            },
            //页面初始化
            pageInit(){
                this.winH = document.documentElement.clientHeight || document.body.clientHeight;
                console.log('this.winH', this.winH);
                //获取url参数
                let schoolId = this.$route.query.school_id || this.schoolId;
                let id = this.$route.query.id;
                this.urlParams = {schoolId,id};
                console.log('this.urlParams', this.urlParams);

            }
        }
    }
</script>

<style lang="scss">
    .codeLoginPage {
        @extend .flex_column;
        overflow: hidden;

        & > div {
            width: 100%;
        }

        .logo {
            /*height: 30%;*/
            @extend .flex_row_center;

            img {
                max-width: 90%;
                object-fit: cover;
                object-position: center;
                /* 最大高度设置 */
                max-height: 90%;
            }
        }

        .form_box {
            @extend .flex_height_auto;
            @extend .flex_column;
            padding: 0 5%;
            width: 90%;
            .van-field__control{
                font-size: 24px;
                /*font-weight: bold;*/
                color: $defaultColor !important;
            }
            .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
                color: $warnColor;
            }
            .van-field__control::placeholder{
                font-size: 16px;
            }
            .form_item{
                /*min-height: 160px;*/
            }
            //判断ipad
            @media only screen
            and (min-device-width : 768px)
            and (max-device-width : 1024px){
                font-size: 42px;
            }
            .inputType{
                @extend .flex_row_center;
                img{
                    height: 20px;
                    width: 20px;
                }
            }

            .submit_btn{
                @extend .flex_row_center;
                width: 100%;
                height: 45px;
                border-radius: 50px;
                background: $themeColor;
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                margin-top: 60px;
                transition: all .2s ease;
                &:active{
                    background: $primaryColor;
                }
                &.disabled{
                    background: #EEEEEE;
                    pointer-events:none;
                }
            }
            .agreement{
                margin-top: 12px;
                font-size: 14px;
                @media only screen
                and (min-device-width : 768px)
                and (max-device-width : 1024px){
                    font-size: 26px;
                }
                .agreement_text{
                    color: #1879E5;
                }

            }
            .user_action{
                margin-top: 24px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: #52B8E8;
            }

        }

        .footer {
            margin-top: 24px;
            padding-bottom: 60px;
            @extend .flex_row_center;

            .action_item {
                flex: 1;
                margin-right: 70px;
                &:last-child {
                    margin-right: 0;
                }

                &.retrieve {
                    /*text-align: right;*/
                    color: $warnColor;
                }

                &.register {
                    /*text-align: left;*/
                    color: $defaultColor;
                }
            }
        }

        //兼容ipad
        @media only screen
        and (min-device-width : 768px)
        and (max-device-width : 1024px){
            .form_box {
                font-size: 42px;
                .van-field__control{
                    font-size: 48px;
                }
                .van-field__error-message{
                    font-size: 24px;
                }
                .van-field__control::placeholder{
                    font-size: 32px;
                }
                .inputType{
                    img{
                        height: 40px;
                        width: 40px;
                    }
                    .get_code{
                        font-size: 28px;
                    }
                }

                .submit_btn{
                    height: 90px;
                    border-radius: 100px;
                    font-size: 36px;
                    margin-top: 120px;
                }
                .agreement{
                    margin-top: 24px;
                    font-size: 26px;

                }

            }

            .footer {
                margin-top: 48px;
                padding-bottom: 120px;

                .action_item {
                    margin-right: 140px;
                }
            }
        }
    }
</style>